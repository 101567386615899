<template>
  <div>
    <div class="text-field-box">
      <long-text-field :text.sync="text" />
    </div>
    <div class="display-text">{{text}}</div>
  </div>
</template>
<script>
export default {
  components: {
    LongTextField: () => import('@/components/long-text-field')
  },
  data () {
    return {
      text: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.text-field-box
  width 300px

.display-text
  border solid 1px black
  height 5em
  width 200px
  overflow-y auto
</style>
